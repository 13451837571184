import React from "react";
import { NavLink } from "react-router-dom";

import logo from "../assets/logo.svg";
import { EmailButton } from "./EmailButton";
import classes from "./Header.module.scss";
import SmartLook from "smartlook-client";

export const Header = () => {
  const registration = () => {
    SmartLook.navigation("/register");
    window.location.href = process.env.REACT_APP_REGISTRATION_URL as string;
  };

  return (
    <>
      <header className={classes.topBar}>
        <div className={classes.top}>
          <NavLink to="/">
            <img
              width="182"
              height="50"
              style={{ cursor: "pointer" }}
              src={logo}
              alt="Logo"
              className={classes.headerLogo}
            />
          </NavLink>
          <nav className={classes.buttonsGroup}>
            <EmailButton onClick={registration} classes={[classes.button]} />
          </nav>
        </div>
      </header>
    </>
  );
};
