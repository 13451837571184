import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { ReactComponent as Facebook } from "../assets/facebook.svg";
import logo from "../assets/footer-logo.svg";
import { ReactComponent as Instagram } from "../assets/instagram.svg";
import classes from "./Footer.module.scss";

export const Footer = () => {
  const getYear = new Date().getFullYear();
  const navigator = useNavigate();

  const handleRoute = (route: string) => {
    navigator(route);
  };

  return (
    <footer className={classes.footer}>
      <div className={classes.footerContainer}>
        <div className={classes.logoContainer}>
          <img src={logo} alt="Sharp Brain" />
        </div>

        <div className={classes.contacts}>
          <div className={classes.paragraphBlock}>
            <p className={classes.textParagraph}>
              Sharp Brain s.r.o IČ 19156405
            </p>
            <p className={classes.textParagraph}>J. Matičky 356,</p>
            <p className={classes.textParagraph}>570 01 Litomyšl</p>
          </div>

          <p className={classes.email}>am@sharpbrain.cz</p>

          <div className={classes.text}>+420 720 408 805</div>
          <div className={classes.socials}>
            <Link
              to="https://www.instagram.com/sharp_brain_cz/"
              target="_blank"
            >
              <Instagram />
            </Link>
            <Facebook />
          </div>
        </div>

        <div className={classes.col1}>
          <div className={classes.link} onClick={() => handleRoute("/contact")}>
            Kontaktujte nás
          </div>
          <div className={classes.link} onClick={() => handleRoute("/jobs")}>
            Práce v Sharp Brain
          </div>
        </div>
        <div className={classes.col2}>
          <div
            className={classes.skillsContainer}
            onClick={() => handleRoute("/skills")}
          >
            <p className={classes.link}>Online vzdělávání</p>
          </div>
          <div className={classes.link} onClick={() => handleRoute("/logic")}>
            Raný rozvoj matematického myšlení
          </div>
        </div>
        <div className={classes.col3}>
          <div
            className={classes.pricingContainer}
            onClick={() => handleRoute("/pricing")}
          >
            <p className={classes.link}>Ceny a slevy</p>
          </div>
        </div>
      </div>
      <div className={classes.footerSecondRow}>
        <div className={classes.copyrightBlock}>
          <span>©</span>
          <span>&nbsp;{getYear}&nbsp;</span>
          <span>Sharpbrain. All rights reserved.</span>
        </div>

        <div
          className={classes.termsContainer}
          onClick={() => handleRoute("/terms")}
        >
          <p>Obchodní podmínky</p>
        </div>

        <div
          className={classes.privacyContainer}
          onClick={() => handleRoute("/privacy")}
        >
          <p>Ochrana osobních údajů</p>
        </div>
      </div>
    </footer>
  );
};
